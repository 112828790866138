import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Collapse from '../components/molecules/Collapse';
import CFS from '../components/molecules/CFS';
import UniversalCard from '../components/molecules/UniversalCard';

import imgR from '../assets/images/pages/covid-19/maria.png';
import img1 from '../assets/images/pages/covid-19/c1.png';
import img2 from '../assets/images/pages/covid-19/c2.png';
import img3 from '../assets/images/pages/covid-19/c3.png';
import '../assets/styles/pages/covid-19.scss';

const Covid19 = () => (
  <Layout
    title="Covid-19 | ELE Barcelona"
    description="Protocolo de medidas de protección: Mascarilla obligatoria. Desinfección de manos. Distancia de seguridad. Grupos reducidos. Ventilación de las clases."
  >
    <div className="covid-19">
      <CFS title="¡Estamos abiertos!" description="Lee nuestra respuesta Covid-19" />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="c-title-34">Nota de la directora de la escuela</h3>
              <p className="second-section__description c-text-18">
                Como a todos vosotros/as, me preocupa la propagación de la Covid-19 en España y en
                elextranjero, así como su posible efecto en estudiantes, empleados y miembros de la
                comunidad.
                <br />
                <br /> Creo que estas circunstancias presentan una oportunidad única para aprender y
                adaptarnos.
                <br />
                <br /> En ELE Barcelona estamos preparados para afrontar juntos esta nueva
                situación. Los últimos meses han sido muy desafiantes, ¡pero estamos seguros de que
                ser docente de español seguirá siendo una carrera viable y atractiva!
                <br />
                <br /> Lo mejor de ser profesor y profesora de español es que ofrece una salida
                laboral que fácilmente puede adaptarse a tus necesidades y puedes dar clases donde
                quieras, tanto online como presencial.
                <br />
                <br /> Mientras tanto, la escuela sigue abierta con grupos súper reducidos lo que
                significa que obtendrás una atención aún más personalizada y más tiempo con tus
                tutores.
              </p>
            </div>
            <div className="col-md-6">
              <div className="right">
                <img className="cta-img" src={imgR} alt="imgR" />
                <div className="overlap">
                  <p>María Blanco</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row bottom">
            <div className="col-lg-5">
              <h2 className="bottom__title c-title-34">
                ¿Cómo protegemos a nuestros estudiantes y personal?
              </h2>
            </div>
            <div className="col-12">
              <p className="bottom__description c-text-18">
                Contamos con las siguientes medidas de seguridad:
              </p>
            </div>
            <div className="col-lg-4">
              <UniversalCard
                title="Distanciamiento físico"
                description={`
                    <ul class="card__list">
                    <li>
                    Los escritorios y las sillas estarán separados por 1.5 metros.
                    </li>
                    <li>Clases escalonadas para evitar congestiones.
                    </li>
                    <li>Uso obligatorio de mascarillas faciales.
                    </li>
                    <li>Las máscaras están disponibles para la venta en la recepción.</li>
                    </ul>
              `}
                img={img1}
              />
            </div>
            <div className="col-lg-4">
              <UniversalCard
                title="Desinfección regular"
                description={`
                    <ul class="card__list">
                    <li>Desinfectante para manos y jabón disponible.</li>
                    <li>Las aulas se desinfectan entre clases.</li>
                    <li>Los baños se limpian con regularidad.</li>
                    <li>Las aulas están debidamente ventiladas.</li>
                    </ul>
              `}
                img={img2}
              />
            </div>
            <div className="col-lg-4">
              <UniversalCard
                title="Área de cocina cerrada"
                description={`
                <ul class="card__list">
                <li>El café, el té, la fruta y las galletas no estarán disponibles temporalmente.</li>
                <li>Las tazas, tazones y platos tampoco estarán disponibles.</li>
                <li>Le pedimos que traiga su propio termo de café o botella de agua.</li>
                <li>Está prohibido comer en la escuela temporalmente.</li>
                </ul>
              `}
                img={img3}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="third-section c-faqs-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="c-title-34">Preguntas frecuentes</h2>
              <p className="c-text-18 third-section__description">
                Contamos con las siguientes medidas de seguridad:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Collapse
                title="La economía es débil. ¿Es ahora un buen momento para invertir en un curso FELE?"
                description={`
                En definitiva, sí, hay una gran demanda de profesores tanto online como presencial y es relativamente fácil encontrar trabajo.
                <br/>Hay algunos puntos que valen la pena recordar antes de invertir en un curso FELE:
                <ul class='faq-list'>
                    <li class='faq-list__item'>La demanda de profesores en línea aumentaba constantemente antes de la
                    pandemia y ahora se encuentra en niveles récord.
                    </li>
                    <li class='faq-list__item'>
                    La educación es más o menos a prueba de recesiones; muchas personas utilizan su
                tiempo de inactividad para estudiar o volver a capacitarse.</li>
                    <li class='faq-list__item'>
                    Los gobiernos han aumentado la financiación para sus planes de reentrenamiento
                de Covid.</li>
                <li class='faq-list__item'>
                La capacitación y calificación que obtienes ahora es válida para siempre y puedes
                usarla para encontrar trabajo en línea o en el aula en todo el mundo.</li>
                </ul>`}
              />
              <Collapse
                title="¿Debo hacer el curso en línea o esperar hasta el próximo año?"
                description={`
                En definitiva, sí, hay una gran demanda de profesores tanto online como presencial y es relativamente fácil encontrar trabajo.
                <br/>Hay algunos puntos que valen la pena recordar antes de invertir en un curso FELE:
                <ul class='faq-list'>
                    <li class='faq-list__item'>La demanda de profesores en línea aumentaba constantemente antes de la
                    pandemia y ahora se encuentra en niveles récord.
                    </li>
                    <li class='faq-list__item'>
                    La educación es más o menos a prueba de recesiones; muchas personas utilizan su
                tiempo de inactividad para estudiar o volver a capacitarse.</li>
                    <li class='faq-list__item'>
                    Los gobiernos han aumentado la financiación para sus planes de reentrenamiento
                de Covid.</li>
                <li class='faq-list__item'>
                La capacitación y calificación que obtienes ahora es válida para siempre y puedes
                usarla para encontrar trabajo en línea o en el aula en todo el mundo.</li>
                </ul>`}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Covid19;
